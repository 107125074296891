import React, { useContext, useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import Slider from "react-slick"

import { StateController } from './../../App'
import AwardBox from '../molecules/AwardBox'
import Button from '../atoms/Button'
import Pagger from '../atoms/Pagger'
import { sendDataLayer }  from '../../utils/functions'

function AwardsGallery({
  awardBoxType,
  itemsPerLine,
  limit,
  limitButtonText,
  list,
  canRedem,
  termText,
  termLink,
  termModal,
  redemtionText,
  steps,
  showStock,
  download,
  twoStepAuth
}) {
  const {context} = useContext(StateController)
  const [opts, setOpts] = useState([])
  const [pages, setPages] = useState(1)
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (context && context.awards) {
      setOpts(
        Object.keys(context.awards).map((key) => context.awards[key])
      )
    }
  }, [context])

  const stepper_settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    customPaging: function(i) {
      return (
        <span key={i}>
          <p>{`Mes ${i+1}`}</p>
        </span>
      )
    }
  }

  const layer = () => {
    sendDataLayer({datalayer: { event: 'progress', text: 'Ver todos los comercios disponibles', link: '/premios' },repeat: false})
  }

  return (
    <div className={`mc-awards-container mc-awards-column-${itemsPerLine}`}>

      {steps
        ? <div className="mc-awards-container__steper">
            <Slider {...stepper_settings}>
              {opts.map((step, index) => {

                return (
                  <div className="mc-awards-container__items" key={index}>
                    {step.awards.map((item, index) => {
                      
                      return (
                        <div key={index} className={`mc-awards-container__items--item type-${awardBoxType}`}>
                          <AwardBox
                            type={awardBoxType}
                            award={item}
                            canRedem={step.canRedem ? step.canRedem : false}
                            termLink={termLink}
                            termText={termText}
                            termModal={termModal}
                            redemtionText={redemtionText}
                            download={download}
                            position={index}
                          />
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </Slider>
          </div>
        : list && list[0] 
        ? <div className="mc-awards-container__items">
            {list.filter((item, i) => {
              let limiter = limit ? i < limit ? true : false : true
              let section = page * 6
              if ((i+1) <= section) {
                if (page === 1) {
                  limiter = true
                } else {
                  let ext = section - 6
                  if ((i+1) > ext) {
                    limiter = true
                  } else {
                    limiter = false
                  }
                }
              } else {
                limiter = false
              }
              return limiter
            }).map((item, index) => {

              if (download && item.prepurchased === 1) item.prepurchased = true
              if (download && item.prepurchased === 0) item.prepurchased = false

              return (
                <div key={index} className={`mc-awards-container__items--item type-${awardBoxType}`}>
                  <AwardBox
                    type={awardBoxType}
                    award={item}
                    canRedem={canRedem}
                    termLink={termLink}
                    termText={termText}
                    termModal={termModal}
                    redemtionText={redemtionText}
                    showStock={showStock}
                    download={download}
                    twoStepAuth={twoStepAuth}
                    position={index}
                  />
                </div>
              )
            })}
          </div>
        : <div className='mc-redemtions-empty'>
            <div className='mc-redemtions-empty__title'>
              Aún no has realizado ninguna redención
            </div>
            <p className='mc-redemtions-empty__subtitle'>
              Continúa usando tu Tarjeta Débito AV Villas, revisa tu progreso y redime tus premios.
            </p>
            <div className='mc-redemtions-empty__actions'>
              <Button
                type="primary"
                text="Ir a progreso"
                loading={false}
                status="active"
                link="/progreso"
              />
              <Button
                type="secondary"
                text="Ver premios"
                loading={false}
                status="active"
                link="/premios"
              />
            </div>
          </div>
      }

      {list && list[0] && !limit && 
        <Pagger
          page={page}
          pages={pages}
          setPage={setPage}
          setPages={setPages}
          awards={list}
        />
      }

      {limit && limitButtonText && (list && list[0]) && 
        <div className="mc-awards-container__actions" onClick={layer}>
          <Link to="/premios">{limitButtonText}</Link>
        </div>
      }
    </div>
  )
}

export default AwardsGallery