import React, { useEffect, useState, createContext } from 'react'
import {
  Route,
  Routes,
  useLocation,
  useSearchParams,
  useNavigate
} from 'react-router-dom'
import { connect } from 'react-redux'

// pages
import Login from "./components/pages/Login"
import Mechanic from "./components/pages/Mechanic"
import Progress from "./components/pages/Progress"
import Awards from "./components/pages/Awards"
import Terms from "./components/pages/Terms"
import Faqs from "./components/pages/Faqs"
import Alliances from "./components/pages/Alliances"
import Dashboard from "./components/pages/Dashboard"
import NotFound from "./components/pages/NotFound"
// layout
import Header from "./layouts/Header/Header"
import Footer from "./layouts/Footer/Footer"

// components
import CustomModal from './components/molecules/CustomModal'
import { sendDataLayer } from './utils/functions'

// actions
import {
  logOutAction,
  loginShaAction
} from './store/sessionDuck'

import {
  gtUser,
  gtStaticContent
} from './utils/helpers'
import { getPrice, getWhateverPage } from './utils/functions'
import { doRedeem } from './utils/helpers'

import './styles/main.scss'

export const StateController = createContext(null)

function App({
  error,
  token,
  loggedIn,
  logOutAction
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [modalOpen, setMdalOpen] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [context, setContext] = useState(false)
  const [staticContent, setStaticContent] = useState(false)
  const [autologinPreloader, setAutologinPreloader] = useState(false)

  const [awardSelected, setAwardSelected] = useState({})
  const [showConfirm, setShowConfirm] = useState(false)
  const [redemtionLoading, setRedemtionLoading] = useState(false)
  const [showErrorRedemtion, setShowErrorRedemtion] = useState(false)
  const [showSuccessRedemtion, setShowSuccessRedemtion] = useState(false)
  const [showErrorAuth, setShowErrorAuth] = useState(false)

  const [authDate, setAuthDate] = useState('')

  const home_page = '/mecanica'
  const pages = [
    {page: 'mecanica', title: '¿Cómo ganar?', icon: `mechanic`, sublevel: false, component: <Mechanic privatePage />},
    {page: 'progreso', title: 'Progreso', icon: `progress`, sublevel: false, component: <Progress privatePage />},
    {page: 'premios', title: 'Premios', icon: `awards`, sublevel: false, component: <Awards privatePage />},
    {page: 'alianzas', title: 'Alianzas', icon: `alliances`, sublevel: false, component: <Alliances privatePage />},
    {page: 'mas', title: 'Otros', icon: `more`, sublevel: true, children: [
      {page: 'terminos-y-condiciones', title: 'Términos y condiciones', icon: 'tyc', component: <Terms />},
      {page: 'preguntas-frecuentes', title: 'Preguntas frecuentes', icon: 'faqs', component: <Faqs />}
    ]}
  ]
  const pathURL = {
    "tZNsUTFTPw": "progreso",
    "WtwuqKUmjp": "mecanica",
    "JnXnducsYR": "premios",
    "MsFaj6v12C": "alianzas",
    "pMLl7K7NL40": "terminos-y-condiciones",
    "dW01D9ysQJ7": "preguntas-frecuentes"
  }

  const closeLoginModal = () => {
    setAutologinPreloader(false)
    setNotFound(false)
    localStorage.removeItem('userNotFound')
    window.location.href = '/'
  }

  useEffect(() => {
    if (localStorage.getItem('userNotFound')) {
      setNotFound(true)

      setTimeout( () => {
        localStorage.removeItem('userNotFound')
      }, 3000 )
    } else {
      setNotFound(false)
    }
  }, [location])

  useEffect(() => {
    if (searchParams.get('utm_web')) {
      setAutologinPreloader(true)
    }

    if (!localStorage.getItem('storage')) {
      if (window.location.search !== '') {
        console.log("Navegate to:", window.location.search)
        // navigate(window.location.search)
      } else {
        const public_url = pages.find((page) => page.page === location.pathname.replace('/', '') )
        const public_children_urls = pages.find((page) => page.sublevel)

        if (public_url) {
          if (!loggedIn && public_url?.component?.props?.privatePage) document.location.href = '/'

        } else if (public_children_urls) {

          const public_child_url = public_children_urls?.children.find((childPage) => childPage.page === location.pathname.replace('/', '') )
          if (public_child_url?.component?.props?.privatePage) {
            console.log("////")
            navigate('/')
          }
        }
      }
    }
  }, [searchParams, error])

  useEffect(() => {

    if (token !== '') {
      console.log("Tengo token:", token)

      gtUser(token).then((user) => {

        console.log("Tengo session:", user)

        if (user?.data) {
          setContext(user.data)
          console.log("Tengo user:", user.data)
          console.log("window.location.pathname", window.location.pathname)

          if (searchParams.get('pathurl')) {
            const path = pathURL[searchParams.get('pathurl')] || home_page
            let queryparams = ''

            for(const x of searchParams.keys()){
              if(!(x === "utm_web" || x==='pathurl')){
                queryparams += `${x}=${searchParams.get(x)}&`
              }
            }
            queryparams = queryparams.slice(0, queryparams.length - 1)
            window.location.href = `${path}${(queryparams && queryparams !== '') && '?'}${queryparams}`
          } else {
            if (window.location.pathname !== home_page && window.location.pathname === '/') {
              window.location.href = home_page
            }
          }

          if (user?.data?.tracing && user?.data?.tracing[`winner_${user.data.block}`] === 1) {
            sendDataLayer({datalayer: { event: 'goal' },repeat: false})
          }

        } else if (user.statusCode === 301 && localStorage.getItem('storage') && window.location.pathname === '/') {
          localStorage.removeItem('storage')
          window.location.reload()
        }
        if (user?.statusCode === 301 && window.location.pathname !== '/' && window.location.pathname !== '/dashboard') {
          window.location.href = '/'
        }
      }).catch((err) =>  {
        console.log("Err process", err)
        navigate('/')
      })

    }
  }, [token])

  useEffect(() => {

    gtStaticContent().then((content) => {
      if (content && content.content) setStaticContent(content.content)
    })
  }, [])

  const handlerModal = () => {
    if (showConfirm) {
      sendDataLayer({datalayer: {event: 'cancel_bond',product_name: awardSelected.name ,product_price: awardSelected.price, product_type: "inteligentes", index: awardSelected.index},repeat: true})
    }
    setShowConfirm(!showConfirm)
    
  }

  const handlerRedemtion = () => {
    setRedemtionLoading(true)
    setShowErrorAuth(false)

    if (awardSelected.canRedem) {
      doRedeem(token, awardSelected.price, awardSelected.id, authDate).then((data) => {
        setRedemtionLoading(false)
        setShowConfirm(false)

        if (data && data.status === 200 && data.file) {
          setShowSuccessRedemtion(true)
          sendDataLayer({datalayer: {event: 'redeem_bond', product_name: awardSelected.name, product_price: awardSelected.price, product_type: "inteligentes", index: awardSelected.index},repeat: true})

          setTimeout( () => {
            window.location.reload()
          }, 5000 )
        } else {
          console.log("Error redemtion!", data)

          if (data.status === 304) {
            setShowConfirm(true)
            setShowErrorAuth(true)
          } else {
            setShowErrorAuth(false)
            sendDataLayer({datalayer: {event: 'exhausted_bond', product_name: awardSelected.name, product_price: awardSelected.price, index: awardSelected.index},repeat: true})
            setShowErrorRedemtion(true)
          }
          
        }
      }).catch(error => {
        console.log("ERROR ON REDENTION:", error)
      })
    }
  }

  return (
    <StateController.Provider
      value = {
        {
          setMdalOpen: setMdalOpen,
          context: context,
          staticContent: staticContent,
          pages: pages,
          awardSelected: awardSelected,
          setAwardSelected: setAwardSelected,
          handlerRootModal: handlerModal
        }
      }
    >
      <CustomModal
        title="Datos incorrectos"
        desc="Verifica tu información y vuelve a intentarlo."
        cta="Aceptar"
        info={true}
        icon={false}
        primaryAction={
          {
            action: closeLoginModal,
            buttonText: 'Cerrar',
            loading: false
          }
        }
        show={notFound} setShowModal={setNotFound}
      />

      <CustomModal
        show={showConfirm}
        redemtion={true}
        setShowModal={setShowConfirm}
        image={awardSelected.image}
        title={`${getPrice(awardSelected.price)}`}
        desc={awardSelected.name}
        position={awardSelected.index}
        showErrorAuth={showErrorAuth}
        primaryAction={
          {
            action: handlerRedemtion,
            buttonText: "Descargar",
            loading: redemtionLoading,
            setAuthDate: setAuthDate
          }
        }
        secondaryAction={
          {
            action: handlerModal,
            buttonText: "Cancelar"
          }
        }
        terms={
          {
            termsText: "Los bonos digitales están sujetos a términos y condiciones de cada comercio aliado, los cuales se especifican en el bono digital que se redime y descarga en la plataforma.",
            termsLink: ""
          }
        }
      />
      <CustomModal
        title="Error en la redención"
        desc={'Ocurrió un error al obtener su bono. Inténtelo de nuevo más tarde.'}
        cta="Aceptar"
        info={true}
        show={showErrorRedemtion} setShowModal={setShowErrorRedemtion}
      />
      <CustomModal
        title="Redención correcta"
        desc={`Su bono ha sido descargado. Encuéntrelo en la parte inferior de la sección “Premios”.`}
        cta="Aceptar"
        info={true}
        show={showSuccessRedemtion} setShowModal={setShowSuccessRedemtion}
      />

      <div className={`
        App ${modalOpen ? 'modalOpen' : ''} 
        ${loggedIn ? 'loggedIn' : 'noLoggedIn'} 
      `}>
        <Header
          headerType={1}
          logo="logo_h.png"
          loboMobile="logo_h.png"
          items={pages}
          submenu={false}
          notifications={
            [
              {
                id: 1,
                title: "Notification",
                content: "html content",
                status: 0
              }
            ]
          }
          loggedIn={loggedIn}
          logOutAction={logOutAction}
        />

        <Routes>
          <Route path="/" element={<Login autologin={autologinPreloader} hash={searchParams.get('utm_web')} />} />

          {/* Root pages */}
          {pages.map((page, i) => {
            return (
              <Route
                key={i}
                path={`/${page.page}`}
                element={page.component}
              />
            )
          })}

          {/* Child pages */}
          {pages.find((page) => {
            return page.sublevel ? true : false
          })?.children.map((page, i) => {
            return (
              <Route
                key={i}
                path={`/${page.page}`}
                element={page.component}
              />
            )
          })}
          <Route path="/dashboard" element={<Dashboard adminPage />} />
          {getWhateverPage(location, pages) && 
            <Route path={`${getWhateverPage(location, pages)}`} element={<NotFound />} />
          }
        </Routes>

        <Footer
          footerType={1}
          footerCardsImg="footer-cards.png"
          bankFooterImg="footer_logos.png"
          links={
            [
              {
                link: "/terminos-y-condiciones",
                text: "Términos y condiciones"
              },
              {
                link: "/preguntas-frecuentes",
                text: "Preguntas frecuentes"
              }
            ]
          }
          linksText="<p>Válido del 15 de julio de 2024 al 15 de agosto de 2024.</p><p>Esta es una campaña oficial del Banco Davivienda.</p>"
          content="<p>Recuerde que por su seguridad Banco Davivienda nunca solicita a través de este medio información confidencial o financiera como usuarios y claves de acceso a nuestros canales, ni números de productos como cuentas, números de tarjetas de crédito o similares. Consulte tasas y tarifas en www.davivienda.com</p>"
        />

      </div>
    </StateController.Provider>
  )
}

const mapState = ({ user: { fetching, loggedIn, token, error } }) => {
  return {
    fetching,
    loggedIn,
    token,
    error
  }
}

export default connect(mapState, { logOutAction, loginShaAction })(App)