import React from 'react'
import { Link } from "react-router-dom"

import ProgressCircle from '../atoms/ProgressCircle'
import Icon from './Icon'
import { getProgressPercent, sendDataLayer } from '../../utils/functions'

function Profile({
  nickname,
  context
}) {

  const datalayer = () => {
    sendDataLayer({datalayer: {event: 'anchor',text: 'Ver más', link: '/progreso'},repeat: true})
  }

  
  return (
    <div className={`mc-profile-card`}>
      <div className={`mc-profile-avatar`}>
        <div className={`mc-profile-avatar__letters slow_ani`}>
          <Icon name="avatar" />
        </div>
      </div>
      <div className={`mc-profile-name slow_ani`}>
        {nickname}
        <div className='mc-profile-progress'>
          <strong>
            Tu progreso {getProgressPercent(context)}%
            <ProgressCircle
              percent={getProgressPercent(context)}
            />
          </strong>
          <Link to={'/progreso'} onClick={datalayer}>
            Ver más
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Profile