import React, { useEffect, useState, useContext} from 'react'

import { StateController } from './../../App'
import Preloader from '../atoms/Preloader'
import Container from '../../layouts/Container/Container'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import useWidth from '../../hooks/useWidth'

function Terms() {
  const { staticContent } = useContext(StateController)
  const [termsHTML, setTermsHTML] = useState(false)
  const { width } = useWidth()

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (staticContent && staticContent.terms && staticContent.terms[0].content) {
      setTermsHTML(staticContent.terms[0].content)
    }
  }, [staticContent])
  
  return (
    <div className='mc-page terms mc-page-terms slow_ani'>

      {width > 0 &&
        <div className='mc-page-content absolute'>
          <div className='mc-page-content__absolute'>
            <Container>
              <VerticalCenter>
                <h1>Términos y condiciones</h1>
                <div className='mc-page-content__separator'>
                  <img src="/assets/images/separator.png" alt="Titulos" />
                </div>
              </VerticalCenter>
            </Container>
          </div>
          <img src={`/assets/images/terms-general-bg${width > 992 ? '-desktop' : ''}.png`} alt="Fondo" />
        </div>
      }
      <Container className="mc-page-container">
        {termsHTML
          ? <div className='mc-static-content'>
              <div dangerouslySetInnerHTML={{ __html: termsHTML }} />
            </div>
          : <Preloader
              type="section"
              invert
            />
        }
      </Container>
    </div>
  )
}
export default Terms