import { 
  URL, 
  GLOBAL_CODE,
  APIKEY 
} from "../constants/constants"

export const getUser = async ({ token }) => {
  const res = await fetch(`${URL}/userdata`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': 'application/json',
      "x-api-key": APIKEY,
      'x-access-token': token,
      'x-access-campaign': GLOBAL_CODE
    },
    redirect: "follow",
    referrerPolicy: "no-referrer"
  })

  if (res) {
    
    return (res.json())
  }
}

export const getStaticContent = async () => {
  const res = await fetch(`${URL}/userdata?static=true`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': 'application/json',
      "x-api-key": APIKEY,
      'x-access-campaign': GLOBAL_CODE
    },
    redirect: "follow",
    referrerPolicy: "no-referrer"
  })

  if (res) {
    
    return (res.json())
  }
}
