import React, { useContext, useEffect, useState } from 'react'

import { StateController } from './../../App'

import Container from '../../layouts/Container/Container'
import AwardsGallery from '../organisms/AwardsGallery'
import Preloader from '../atoms/Preloader'
import Icon from '../atoms/Icon'
import Benefits from '../organisms/Benefits'
import useWidth from '../../hooks/useWidth'
import AwardBox from '../molecules/AwardBox'

import { 
  getMaximumBlock
} from '../../utils/functions'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'

function Awards() {
  const [awards, setAwards] = useState(false)
  const [redemtions, setRedemtions] = useState(false)
  const [canRedem, setCanRedem] = useState(false)
  const {context} = useContext(StateController)
  const { width } = useWidth()
  
  useEffect(() => {
    if (context && context.awards) {
      setAwards(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].awards
      )
      setCanRedem(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].canRedem
      )
      setRedemtions(
        context.redemtions
      )
    }
  }, [context])

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='mc-page awards mc-page-awards slow_ani'>

      {width > 0 &&
        <div 
          className='mc-page-content' 
          style={{"backgroundImage": `url(/assets/images/login-bg${width > 992 ? '-desktop' : ''}.png)`}}
        >
          <Container>
            <div className='mc-page-content__master'>
              <div className='mc-page-content__inner'>
                <VerticalCenter>
                  <h1>¡Elija su comercio favorito y redima su bono!</h1>
                  <div className='mc-page-content__separator'>
                    <img src="/assets/images/separator.png" alt="Titulos" />
                  </div>
                  <p className='normal'>Descubra todas las opciones para redimir su bono una vez alcance su meta.</p>
                </VerticalCenter>
              </div>

              <img src={`/assets/images/awards_banner_${width < 992 ? 'mobile' : 'desktop'}.png`} alt="Mujer Celular" />
            </div>

            {awards 
              ? <AwardsGallery
                  awardBoxType={3}
                  itemsPerLine={3}
                  limit={false}
                  limitButtonText="Ver más comercios"
                  showStock={false}
                  list={awards}
                  steps={false}
                  canRedem={canRedem}
                  twoStepAuth={false}
                  redemtionText="Redima su premio"
                  termText="Aplican términos y condiciones."
                  termLink="/terminos-y-condiciones"
                />
              : <Preloader
                  type="section"
                  invert
                />
            }
          </Container>

            <div className='mc-page-content__redemptionsOnAwards'>
              <div className='mc-page-content__redemptionsOnAwards--labels'>
                <h3>¡Usted es todo un Experto de las compras!</h3>
                <p>Descargue su bono y comience a disfrutar de él.</p>
              </div>

              <div className={`mc-page-content__redemptionsOnAwards--awards ${redemtions.length === 0 ? 'empty' : ''}`}>
                {redemtions.length === 0
                  ? <VerticalCenter>
                      <span>
                        <Icon name="empty" />
                        Aquí encontrará su redención.
                      </span>
                    </VerticalCenter>
                  : redemtions[0] && <AwardBox
                      type={3}
                      award={redemtions[0]}
                      canRedem={true}
                      redemtionText={'Descargar'}
                      download={true}
                      position={1}
                    />
                }
                
              </div>
            </div>
            
          <Container>
            <Benefits
              cta="Descubrir"
              link="/alianzas"
            />
          </Container>
        </div>
      }
    </div>
  )
}
export default Awards