import React, { useState, useEffect } from 'react'

import Container from '../../layouts/Container/Container'
import CustomModal from '../molecules/CustomModal'
import SliderRotator from '../organisms/SliderRotator'
import Button from '../atoms/Button'
import useWidth from '../../hooks/useWidth'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Benefits from '../organisms/Benefits'
import { sendDataLayer } from '../../utils/functions'

function Mechanic() {
  const [ending, setEnding] = useState(false)
  const [preEnding, setPreEnding] = useState(false) 
  const [pendingRedemtion, setPendingRedemtion] = useState(false) 
  const [thanks, setThanks] = useState(false) 
  const [outStock, setOutStock] = useState(false) 
  const { width } = useWidth()
  const [customSliderStep, setCustomSliderStep] = useState(1) 

  const handleProgress = async () => {
    sendDataLayer({datalayer: {event: 'steps',text: "Conocer mi progreso", link: "/progreso"},repeat: true})
  }

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const handleGoTo1 = async () => {
    setCustomSliderStep(1)
  }
  const handleGoTo2 = async () => {
    setCustomSliderStep(2)
  }

  return (
    <div className='mc-page mechanic slow_ani'>

      <CustomModal
        title="¡El Match perfecto ha terminado!"
        desc="¿Viste que tu Tarjeta Débito Mastercard AV Villas, sí paga? Aún tienes un bono por redimir, ¡aprovéchalo!"
        cta="Ver bonos disponibles"
        image={'modal_ending.png'}
        show={ending} setShowModal={setEnding}
        globalModal={true}
        link="/premios"
      />
      <CustomModal
        title="La campaña pronto terminará"
        desc="Sigue comprando con tu Tarjeta Débito Mastercard AV Villas para que alcances tu meta. ¡No dejes pasar la oportunidad!"
        cta="Ver mi progreso"
        image={'modal_preending.png'}
        show={preEnding} setShowModal={setPreEnding}
        globalModal={true}
        link="/progreso"
      />
      <CustomModal
        title="Redención pendiente"
        desc="¡Aún hay tiempo para redimir tu bono! Tienes hasta el 1 de julio de 2024."
        cta="Ver bonos disponibles"
        image={'modal_pending.png'}
        show={pendingRedemtion} setShowModal={setPendingRedemtion}
        globalModal={true}
        link="/premios"
      />
      <CustomModal
        title="¡Gracias por participar!"
        desc="Como viste tú y tu Tarjeta Débito Mastercard AV Villas hacen el mejor Match. Prepárate, porque viene mucho más."
        cta="Aceptar"
        image={'modal_thanks.png'}
        show={thanks} setShowModal={setThanks}
        globalModal={true}
      />
      <CustomModal
        title="Bonos agotados"
        desc="Ya no te quedan bonos para redimir, pero sigue pagando con tu Tarjeta Débito Mastercard AV Villas para conseguir más."
        cta="Aceptar"
        image={'modal_outstock.png'}
        show={outStock} setShowModal={setOutStock}
        globalModal={true}
      />

      {width > 0 &&
        <div 
          className='mc-page-content' 
          style={{"backgroundImage": `url(/assets/images/login-bg${width > 992 ? '-desktop' : ''}.png)`}}
        >
          
          <Container>
            <div className='mc-page-content__primary'>
              <VerticalCenter>
                <h1>Así ganan los Expertos</h1>
                <div className='mc-page-content__separator'>
                  <img src="/assets/images/separator.png" alt="Titulos" />
                </div>
                <p>
                  <strong style={{fontWeight: "bold"}}>Use su Tarjeta de Crédito Davivienda Mastercard,</strong><br />cumpla sus metas y redima su bono.
                </p>
              </VerticalCenter>

              <SliderRotator
                mobileItemsToShow={3}
                desktopItemsToShow={3}
                customSliderStep={customSliderStep}
                list={
                  [
                    {
                      id: 1,
                      image: `slider_1.jpeg`,
                      title: "Use su Tarjeta",
                      desc: "Compre en comercios<br /> físicos y digitales."
                    },
                    {
                      id: 2,
                      image: `slider_2.jpeg`,
                      title: "Logre sus metas",
                      desc: "Consulte cuál es el monto y cantidad de transacciones que debe cumplir."
                    },
                    {
                      id: 3,
                      image: `slider_3.jpeg`,
                      title: "Descargue, redima y disfrute",
                      desc: "Al alcanzar su meta podrá descargar su bono y redimirlo."
                    }
                  ]
                }
              />

              <div className={`mc-page-content__primary--customDots step-${customSliderStep}`}>
                <button className='mc-page-content__primary--customDots--buttonLeft'onClick={handleGoTo1}></button>
                <div className='mc-page-content__primary--customDots--steps'>
                  <div className={`mc-page-content__primary--customDots--steps__step ${customSliderStep === 1 ? 'active' : ''}`} onClick={handleGoTo1}></div>
                  <div className={`mc-page-content__primary--customDots--steps__step ${customSliderStep === 2 ? 'active' : ''}`} onClick={handleGoTo2}></div>
                </div>
                <button className='mc-page-content__primary--customDots--buttonRight' onClick={handleGoTo2}></button>
              </div>
            </div>

            <div className='mc-page-content__secondary'>
              <div className='mc-page-content__separator'>
                <img src="/assets/images/separator.png" alt="Titulos" />
              </div>

              <p>¡Demuestre que es un verdadero Experto de las Compras Maestras! Planifique sus compras, progrese y gane.</p>
              <div onClick={handleProgress} className='mc-page-content__ctas'>
                <Button
                  type="primary"
                  text="Conocer mi progreso"
                  loading={false}
                  status="active"
                  link="/progreso"
                />
              </div>
            </div>
            
            <Benefits
              cta="Descubrir"
              link="/alianzas"
            />
              
          </Container>
        </div>
      }


    </div>
  )
}
export default Mechanic