import React, { useEffect, useContext } from 'react'

import { StateController } from './../../App'
import Container from '../../layouts/Container/Container'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import FaqsFeed from '../organisms/FaqsFeed'
import Preloader from '../atoms/Preloader'
import useWidth from '../../hooks/useWidth'

function Faqs() {
  const { staticContent } = useContext(StateController)
  const { width } = useWidth()

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='mc-page faqs mc-page-faqs slow_ani'>
      {width > 0 &&
        <div className='mc-page-content absolute'>
          <div className='mc-page-content__absolute'>
            <Container>
              <VerticalCenter>
                <h1>Preguntas frecuentes</h1>
                <div className='mc-page-content__separator'>
                  <img src="/assets/images/separator.png" alt="Titulos" />
                </div>
              </VerticalCenter>
            </Container>
          </div>
          <img src={`/assets/images/terms-general-bg${width > 992 ? '-desktop' : ''}.png`} alt="Fondo" />
        </div>
      }
      <Container className="mc-page-container">
        {staticContent
          ? <FaqsFeed
              faqsType={1}
              list={staticContent.faqs}
            />
          : <Preloader
              type="section"
              invert
            />
        }
      </Container>
    </div>
  )
}
export default Faqs