import React from 'react'
import PrimaryBanner from '../molecules/PrimaryBanner'
import Button from '../atoms/Button'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Container from '../../layouts/Container/Container'
import useWidth from '../../hooks/useWidth'

function NotFound() {
  const { width } = useWidth()

  return (
    <div className='mc-page not-found slow_ani'>
      <div className='not-found--hero'>
        <Container className="mc-app-fullheight">
          <VerticalCenter>
            <img src='/assets/images/banner_404.png' alt="Not found" />
            <div className='not-found--hero__layout'>
              <h3>No es posible encontrar la página que busca. Revise cómo va su progreso para poder redimir ese premio que tanto le gusta.</h3>
              <Button
                type="primary"
                text="Conocer mi progreso"
                loading={false}
                status="active"
                link="/progreso"
              />
            </div>
          </VerticalCenter>
        </Container>
      </div>

      <PrimaryBanner
        image={`notfound_${width < 992 ? 'mobile':'desktop'}.png`}
      />
    </div>
  )
}
export default NotFound