import React from 'react'
import { useNavigate } from 'react-router-dom'

import Icon from './Icon'

function Button({
  type,
  text,
  loading,
  status,
  onClick,
  link,
  icon
}) {
  const navigate = useNavigate()
  const handler = () => {

    if (status === 'active' && !loading) {
      link
        ? navigate(link)
        : onClick()
    }
  }

  return (
    <div
      className={`mc-button-container mc-button-container__${type}`}
      onClick={handler}
    >
      <button className={`slow_ani mc-button-container__status--${status}`}>
        {loading
          ? <img src='/assets/images/preloader_1.gif' alt='loader' className='inline-loader' />
          : icon 
            ? <><Icon name={icon} />{text}</>
            : text
        }
      </button>
    </div>
  )
}

export default Button