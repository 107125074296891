import { sendDataLayer } from '../../utils/functions'
import React, { useState } from 'react'
import CustomModal from './CustomModal'

function AlliedBox({
  type,
  allied,
  termLink,
  termText,
  termsOnModal
}) {
  const [showOnModal, setShowOnModal] = useState(false)
  const dataLayerAllied =(e, content, link)=>{
    sendDataLayer({datalayer: {event: 'alliances', text: content, link: link},repeat: true})  
  }
  const modalToggler = () => {
    setShowOnModal(!showOnModal)
    sendDataLayer({datalayer: {event: 'alliances', text: allied.name, link: "modal"},repeat: true}) 
  }

  return (
    <div className={`mc-alliances-container__items--item__container ${termsOnModal ? 'terms' : ''}`}>
      {type === 1 &&
        <>
          <div className="mc-alliances-container__items--item__image">
            <span style={{"backgroundImage": `url(/assets/images/${allied.image})`}}>
              <img src="/assets/images/dummy.png" alt="Dummy" className='dummy' />
            </span>
          </div>
          <div className="mc-alliances-container__items--item__info">
            <div className="mc-alliances-container__items--item__title">{allied.name}</div>
            <div className="mc-alliances-container__items--item__desc">
              <div dangerouslySetInnerHTML={{ __html: allied.description }} />
            </div>
            {termLink &&
              termsOnModal
                ? <button className='mc-alliances-button' onClick={modalToggler}>
                    {termText}
                  </button>
                : <a onClick={(e) =>dataLayerAllied(e,allied)} href={allied.link} target='_blank' rel="noreferrer">
                    {termText}
                  </a>
            }
          </div>
        </>
      }
      {type === 2 &&
        <>
          <div className="mc-alliances-container__items--item__image">
            <span style={{"backgroundImage": `url(/assets/images/${allied.image})`}}>
              <img src="/assets/images/dummy.png" alt="Dummy" className='dummy' />
            </span>
          </div>
          <div className="mc-alliances-container__items--item__info">
            <div className="mc-alliances-container__items--item__rule" dangerouslySetInnerHTML={{ __html: allied.rule }} />
            <div className="mc-alliances-container__items--item__title">{allied.name}</div>
          </div>
          <div className="mc-alliances-container__items--item__desc">
            <div dangerouslySetInnerHTML={{ __html: allied.description }} />
          </div>
          {termLink &&
            termsOnModal
              ? <button className='mc-alliances-button' onClick={modalToggler}>
                  {termText}
                </button>
              : <a onClick={(e) =>dataLayerAllied(e,allied)} href={allied.link} target='_blank' rel="noreferrer">
                  {termText}
                </a>
          }
        </>
      }
      {type === 3 &&
        <>
          <div className="mc-alliances-container__items--item__info">
            <div className="mc-alliances-container__items--item__image">
              <span style={{"backgroundImage": `url(/assets/images/${allied.image})`}}>
                <img src="/assets/images/dummy.png" alt="Dummy" className='dummy' />
              </span>
            </div>
            <div className="mc-alliances-container__items--item__labels">
              <div className="mc-alliances-container__items--item__title">{allied.name}</div>
              <div className="mc-alliances-container__items--item__rule">{allied.rule}</div>
            </div>
          </div>
          <div className="mc-alliances-container__items--item__desc">
            <div dangerouslySetInnerHTML={{ __html: allied.description }} />
          </div>
          {termLink &&
            <a onClick={(e) =>dataLayerAllied(e,allied.name)} href={allied.link} target='_blank' rel="noreferrer">
              {termText}
            </a>
          }
        </>
      }
      {type === 4 &&
        <>
          <div className="mc-alliances-container__items--item__info">
            <div className="mc-alliances-container__items--item__image">
              <span style={{"backgroundImage": `url(/assets/images/${allied.image})`}}>
                <img src="/assets/images/dummy.png" alt="Dummy" className='dummy' />
              </span>
            </div>
            <div className="mc-alliances-container__items--item__labels">
              <div className="mc-alliances-container__items--item__title">{allied.name}</div>
              <div className="mc-alliances-container__items--item__rule">{allied.rule}</div>
              <div className="mc-alliances-container__items--item__desc">
                <div dangerouslySetInnerHTML={{ __html: allied.description }} />
              </div>
              {termLink &&
                <a onClick={(e) =>dataLayerAllied(e,allied.name)} href={allied.link} target='_blank' rel="noreferrer">
                  {termText}
                </a>
              }
            </div>
          </div>
        </>
      }

      <CustomModal
        title={`Obtenga un ${allied.rule} de descuento`}
        desc={allied.description}
        body={allied.termsData}
        cta="Aceptar"
        icon={false}
        image={allied.image}
        show={showOnModal} setShowModal={setShowOnModal}
        termsOnModal={true}
        rule={allied.rule}
      />
    </div>
  )
}

export default AlliedBox