import React from 'react'

function Section({
  children,
  title,
  light
}) {

  return (
    <div className={`mc-section ${light ? 'isLight' : ''}`}>
      {title &&
        <h2>{title}</h2>
      }
      {children}
    </div>
  )
}

export default Section